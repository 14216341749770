import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/router/permission";

import "@/assets/index.scss";
import VxeUI from 'vxe-pc-ui';
import 'vxe-pc-ui/lib/style.css'

const app = createApp(App);
app.use(store).use(router).use(ElementPlus).use(VxeUI).use(VXETable).mount('#app');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
