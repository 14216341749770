import router from '.';
import { isLogined } from '@/api/userApi';
import { autoRefreshToken } from '@/utils/jwtApi';

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    if (isLogined() === false) {
        if (whiteList.indexOf(to.path) !== -1) {
            console.log('not logined');
            next();
        }
        else {
            next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
        }
    } else {
        if (to.path === '/login') {
            next();
        }
        else {
            autoRefreshToken();
            next();
        }
    }
})
